import axios from './axios';

// Fetch user session (either protected or standard) based on authentication state
export const fetchSessionId = async (isAuthenticated, getAccessTokenSilently) => {
    let sessionId;
    
    if (isAuthenticated) {
        
    } else {
        const existingSessionId = localStorage.getItem('session_id');
        if (existingSessionId) {
            return existingSessionId;
        } else {
            const response = await axios.get('/users/session');
            sessionId = response.data.session_id;
            localStorage.setItem('session_id', sessionId);
        }
    }

    return sessionId;
};

// Lock for fetch Video Process
let fetchRequestInProgress = null;

export const fetchUserVideos = async (isAuthenticated, user, session_id, getAccessTokenSilently, maxRetries = 3) => {
  if (fetchRequestInProgress) {
    return fetchRequestInProgress;
  }

  let attempts = 0;

  const fetchData = async () => {
    try {
      let response;
      if (isAuthenticated && user) {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        response = await axios.get('/videos/uservideos', { headers });
      } else {
        response = await axios.get('/videos/sessions/' + session_id);
      }
      return response.data.data; // Assuming the response has a "data" key with video details
    } catch (error) {
      attempts++;
      if (attempts <= maxRetries) {
        console.warn(`Retrying fetchUserVideos (${attempts}/${maxRetries})...`);
        await new Promise((resolve) => setTimeout(resolve, 2 ** attempts * 100)); // Exponential backoff
        return fetchData(); // Retry
      } else {
        console.error("Failed to fetch videos after retries:", error);
        throw error;
      }
    }
  };

  fetchRequestInProgress = fetchData();

  try {
    const result = await fetchRequestInProgress;
    return result;
  } finally {
    fetchRequestInProgress = null; // Reset lock regardless of success or failure
  }
};

// Upload a video
export const uploadVideo = async (file, session_id, metadata, getAccessTokenSilently) => {
    const token = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('session_id', session_id);
    formData.append('metadata', JSON.stringify(metadata));

    const response = await axios.get('/videos/upload-url', formData, { headers });
    return response.data;
};


// Cut video by ID
export const cutVideo = async (videoId, cuts, session_id, token = null) => {
  const headers = token
    ? { Authorization: `Bearer ${token}` } // Include the token if available
    : {};

  const body = {
    videoId,
    cuts,
    sessionId: session_id,
  };

  try {
    const response = await axios.post('/videos/cut', body, { headers });
    return response.data;
  } catch (error) {
    console.error('Error in cutVideo:', error);
    throw error;
  }
};

// Fetch user information
export const fetchUserInformation = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/users', { headers });
    return response.data.user_info;
};

// Fetch user options
export const fetchUserOptions = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/users/options', { headers });
    return response.data.user_options;
};

// Fetch user settings
export const fetchUserSettings = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/users/settings', { headers });
    return response.data.user_settings;
};

// Fetch user details
export const fetchUserDetails = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/users/details', { headers });
    return response.data.user_details;
};

// Check if nickname exists
export const checkNicknameAvailability = async (token, nickname) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get(`/users/existence?nickname=${nickname}`, { headers });
    return response.data;
};

// Update user information TODO: change to new endpoint
export const updateUserInformation = async (token, userInfo) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.put('/users/information', userInfo, { headers });
    return response.data;
};

// Update user information TODO: change to new endpoint
export const updateUserOptions = async (token, userOptions) => {
  const headers = { Authorization: `Bearer ${token}` };
  const response = await axios.put('/users/options', userOptions, { headers });
  return response.data;
};

// Update user information TODO: change to new endpoint
export const updateUserDetails = async (token, userDetails) => {
  const headers = { Authorization: `Bearer ${token}` };
  const response = await axios.put('/users/details', userDetails, { headers });
  return response.data;
};

// Update profile picture
export const updateProfilePicture = async (token, profilePicture) => {
    const headers = { Authorization: `Bearer ${token}` };
    const formData = new FormData();
    formData.append('profilePicture', profilePicture);
    const response = await axios.post('/users/profile-picture', formData, { headers });
    return response.data;
};

// Function to get the presigned URL for file upload
export const getPresignedUrl = async (file, session_id, metadata, getAccessTokenSilently, isAuthenticated, user) => {
    const params = {
      fileName: file.name,
      fileType: file.type,
      sessionId: session_id,
      fileMetadata: metadata, // Add the metadata to the params
    };
  
    let response;
    try {
      if (isAuthenticated && user) {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        response = await axios.get('/videos/upload-S3-video', { headers, params });
      } else {
        response = await axios.get('/videos/upload-S3-video', { params });
      }
  
      return response.data; // Should return presigned URL and any additional data
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      throw error;
    }
  };

  // Function to send metadata to the backend
export const sendVideoMetadata = async (fileId, metadata, sessionId, getAccessTokenSilently, isAuthenticated) => {
    const payload = {
      fileId,
      metadata,
      sessionId,
    };
  
    // Add authentication headers if required
    let response;
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      response = await axios.post('/videos/metadata', payload, { headers });
    } else {
      response = await axios.post('/videos/metadata', payload);
    }
  
    return response.data;
  };
  
  // Upload the file to the S3 presigned URL
  export const uploadFileToS3 = async (presignedUrl, file, updateUploadProgress, fileId) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateUploadProgress(progress, fileId);
      },
    };
  
    await axios.put(presignedUrl, file, options);
  };

  // Download video by URL
export const downloadVideo = async (videoUrl, fileName) => {
  try {
    const response = await fetch(videoUrl, { method: 'GET' });
    if (!response.ok) throw new Error('Failed to fetch the video file');
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during download:', error);
    throw error;
  }
};

// Delete video by ID
export const deleteVideo = async (videoId, isAuthenticated, getAccessTokenSilently) => {
  try {
    // Prepare headers conditionally based on authentication
    let headers = {};
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      headers = { Authorization: `Bearer ${token}` };
    }

    // Make the DELETE request
    const response = await axios.delete(`/videos/${videoId}`, { headers });

    // Check for successful deletion
    if (response.status !== 200) {
      throw new Error('Failed to delete video');
    }

    return response.data; // Return the success message from the backend
  } catch (error) {
    console.error('Error deleting video:', error);
    throw error; // Re-throw the error for the calling function to handle
  }
};


// get video progress by ID
export const getVideoProgress = async (videoId, retryCount = 5) => {
  let attempt = 0;
  
  while (attempt < retryCount) {
    try {
      const response = await axios.get(`/videos/${videoId}/progress`);
      if (response.status !== 200) throw new Error('Failed to get video progress');
      return response.data;
    } catch (error) {
      attempt++;
      if (attempt >= retryCount) {
        console.error('Error getting video progress after retries:', error);
        throw error;
      }
      console.warn(`Retry attempt ${attempt} after error: ${error.message}`);
      await new Promise(resolve => setTimeout(resolve, 5000)); // Retry after 5 seconds
    }
  }
};

// Process video(s) by ID(s)
export const processVideo = async (videoIds, isAuthenticated, getAccessTokenSilently) => {
  try {
    // Prepare headers conditionally based on authentication
    let headers = {};
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      headers = { Authorization: `Bearer ${token}` };
    }

    // Make the POST request
    const response = await axios.post(
      '/videos/process',
      { videoIds }, // Payload
      { headers }   // Headers
    );

    // Check for successful processing
    if (response.status !== 202) {
      throw new Error('Failed to initiate video processing');
    }

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error processing video(s):', error);
    throw error; // Re-throw the error for the calling function to handle
  }
};


// Poll for video processing progress
export const pollVideoProgress = async (progressUrl) => {
  try {
    const response = await axios.get(progressUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching progress:', error);
    throw error;
  }
};

// Update hashtags for a video
export const updateVideoHashtags = async (videoId, hashtags, isAuthenticated, getAccessTokenSilently) => {
  try {
    // Prepare headers conditionally based on authentication
    let headers = {};
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      headers = { Authorization: `Bearer ${token}` };
    }

    // Make the PUT request to update hashtags
    const response = await axios.put(
      `/videos/${videoId}/hashtags`,
      { hashtags },
      { headers }
    );

    // Check for successful update
    if (response.status !== 200) {
      throw new Error('Failed to update hashtags');
    }

    return response.data; // Return the backend response
  } catch (error) {
    console.error('Error updating hashtags:', error);
    throw error; // Re-throw the error for the calling function to handle
  }
};
