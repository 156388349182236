import React, { useState, useEffect, useRef } from 'react';
import { FaDownload, FaTrash, FaEllipsisV, FaInfoCircle, FaCog, FaPencilAlt, FaTimes } from 'react-icons/fa';
import './VideoListItem.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDate, formatDuration } from '../../services/utilityService';
import { downloadVideo, processVideo, getVideoProgress, updateVideoHashtags } from '../../services/apiService';

function VideoListItem({ video, isPlaying, onVideoPlay, onDelete, onEdit, onSelect, isSelected, isSwipeMode, isProcessing, onCloseSwipeMode }) {
  const [showOptions, setShowOptions] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [processingError, setProcessingError] = useState(false);
  const [progress, setProgress] = useState(null);
  const [hashtags, setHashtags] = useState(video.hashtags || '');
  const [isEditingHashtags, setIsEditingHashtags] = useState(false);
  const { metadata } = video;
  const videoRef = useRef(null);
  const optionsRef = useRef(null);
  const infoRef = useRef(null);
  const { isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [isVisible, setIsVisible] = useState(false);

  // Load Video only when visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Detect visibility
      },
      { threshold: 0.1 } // Trigger when 10% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isProcessing) {
      console.log(`Processing video: ${video.id}`);
  
      // Trigger the video processing logic
      const processVideoAsync = async () => {
        try {
          setProcessingError(false);
          setProgress(1); // Start progress at 1%
          
          // Call the API to start processing
          await processVideo([video.id], isAuthenticated, getAccessTokenSilently);
          
          // Poll for progress updates
          const pollProgress = async () => {
            try {
              const progressData = await getVideoProgress(video.id);
  
              const progress = progressData?.data?.progress?.percent_complete;
              if (progress === null || progress === undefined) {
                console.log('Progress data is not yet available. Polling again...');
                setTimeout(pollProgress, 1000);
                return;
              }
  
              if (progress < 100) {
                setProgress(progress);
                setTimeout(pollProgress, 1000);
              } else {
                setProgress(null);
              }
            } catch (error) {
              console.error('Error fetching progress:', error);
              setProcessingError(true);
              toast.error('Failed to fetch processing progress.');
            }
          };
  
          pollProgress();
        } catch (error) {
          console.error('Failed to process video:', error);
          setProcessingError(true);
          toast.error('Failed to start video processing.');
        }
      };
  
      processVideoAsync();
    }
  }, [isProcessing, video.id, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    // Close dropdowns if clicked outside
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        setShowInfo(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  // Handle video checkbox selection
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    onSelect(video.id, checked);
  };

  // Handle video download action
  const handleDownloadClick = async (event) => {
    event.stopPropagation();
    setShowOptions(false);
    try {
      await downloadVideo(video.url, video.original_name);
    } catch (error) {
      toast.error('Failed to start download.');
    }
  };

  // Handle video delete action
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setShowOptions(false);
    try {
      onDelete(video.id);
    } catch (error) {
      toast.error('Failed to delete video.');
    }
  };

  // Toggle options dropdown
  const handleOptionsClick = (event) => {
    event.stopPropagation();
    setShowOptions(!showOptions);
  };

  // Toggle info dropdown
  const handleInfoClick = (event) => {
    event.stopPropagation();
    setShowInfo(!showInfo);
  };

  // Handle video edit action
  const handleEditClick = (event) => {
    event.stopPropagation();
    setShowOptions(false);
    onEdit(video);
  };

  // Handle video play/pause action
  const handlePlayClick = async (event) => {
    event.stopPropagation();
    if (video.status === 'Uploading...') return;

    if (videoRef.current) {
      try {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          await videoRef.current.play();
          onVideoPlay(video.id);
        }
      } catch (error) {
        console.error('Error attempting to play:', error);
        toast.error('Failed to play video.');
      }
    }
  };

  // Handle video processing action
const handleProcessClick = async (event) => {
  event.stopPropagation();
  setProcessingError(false);
  setShowOptions(false);

  try {
    setProgress(1); // Set initial progress to 1%
    await processVideo([video.id], isAuthenticated, getAccessTokenSilently); // Wrap video.id in an array

    // Poll video processing progress
    const pollProgress = async () => {
      try {
        const progressData = await getVideoProgress(video.id);

        // Check if progress data is valid and has a percent_complete value
        const progress = progressData?.data?.progress?.percent_complete;
        if (progress === null || progress === undefined) {
          // If progress is null or undefined, continue polling
          console.log('Progress data is not yet available. Polling again...');
          setTimeout(pollProgress, 1000);
          return;
        }
        if (progress < 100) {
          // Continue polling if progress is incomplete
          setProgress(progress);
          setTimeout(pollProgress, 1000);
        } else {
          // Complete progress
          setProgress(null);
        }
      } catch (error) {
        console.error('Error fetching progress:', error);
        setProcessingError(true);
        toast.error('Failed to fetch processing progress.');
      }
    };

    pollProgress();
  } catch (error) {
    setProcessingError(true);
    setProgress(null); // Reset progress
    toast.error('Failed to start video processing.');
  }
};

// Validate hashtags - Could be moved to utils
const validateHashtags = (tags) => {
  const regex = /^(#\w+)+$/;
  return regex.test(tags);
};

// Handle saving hashtags
const handleSaveHashtags = async () => {
  // Allow no hashtags
  if (hashtags.length === 0) {
    setIsEditingHashtags(false);
    video.hashtags = []; // Clear hashtags
    try {
      await updateVideoHashtags(video.id, [], isAuthenticated, getAccessTokenSilently); // Save empty list
      toast.success('Hashtags cleared successfully!');
    } catch (error) {
      console.error('Error clearing hashtags:', error);
      toast.error('Failed to clear hashtags.');
    }
    return;
  }

  if (!validateHashtags(hashtags)) {
    toast.error('Invalid hashtags. Only letters, numbers, underscores, and # are allowed.');
    return;
  }

  const sanitizedHashtags = DOMPurify.sanitize(hashtags);
  setIsEditingHashtags(false);
  video.hashtags = sanitizedHashtags;

  const hashtagArray = sanitizedHashtags
    .split('#') // Split at every #
    .map((tag) => tag.trim()) // Trim whitespace
    .filter(Boolean) // Remove empty entries
    .map((tag) => `#${tag}`); // Reattach # to each tag


  try {
    await updateVideoHashtags(video.id, hashtagArray, isAuthenticated, getAccessTokenSilently);
  } catch (error) {
    toast.error('Failed to save hashtags.');
  }
};

// Handle Enter key press for saving hashtags
const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    handleSaveHashtags();
  }
};

  return (
    <li className={`video-item ${isSwipeMode ? 'swipe-mode' : ''}`}>
      <div className="video-container">
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          src={isVisible ? video.url : null} // Load video only when visible
          playsInline
          controls
          webkit-playsinline="true" // For older iOS versions
          preload="none"
          onError={() => {
            toast.error('Failed to load video.');
          }}
        >
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Exit button in top-right corner */}
      {isSwipeMode && (
        <button className="exit-button" onClick={onCloseSwipeMode}>
          <FaTimes />
        </button>
      )}

      {/* Info and Function Dropdowns - Always Visible */}
      <FaEllipsisV onClick={handleOptionsClick} className="options-icon" />
      {showOptions && (
        <div className="options-dropdown" ref={optionsRef}>
          <button
            onClick={handleDownloadClick}
            className={`download-button ${video.status === 'Uploading...' ? 'disabled' : ''}`}
            disabled={video.status === 'Uploading...'}
          >
            <FaDownload /> <span className="button-text">Download</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className={`delete-button ${video.status === 'Uploading...' ? 'disabled' : ''}`}
            disabled={video.status === 'Uploading...'}
          >
            <FaTrash /> <span className="button-text">Delete</span>
          </button>
          <button
            onClick={handleProcessClick}
            className={`process-button ${video.status === 'Uploading...' ? 'disabled' : ''}`}
            disabled={video.status === 'Uploading...'}
          >
            <FaCog /> <span className="button-text">Process</span>
          </button>
          <button
            onClick={handleEditClick}
            className={`edit-button ${video.status === 'Uploading...' ? 'disabled' : ''}`}
            disabled={video.status === 'Uploading...'}
          >
            <FaPencilAlt /> <span className="button-text">Edit</span>
          </button>
        </div>
      )}

      <FaInfoCircle onClick={handleInfoClick} className="info-icon" />
      {showInfo && (
        <div className="info-dropdown" ref={infoRef}>
          <p>{`Name: ${video.original_name}`}</p>
          <p>{metadata?.upload_date ? `Date: ${metadata.upload_date ? new Date(metadata.upload_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: '2-digit' }) : 'N/A'}` : 'N/A'}</p>
          <p>{metadata?.duration ? `Duration: ${formatDuration(metadata.duration)}` : 'N/A'}</p>
          <p>{metadata ? `Resolution: ${metadata.width}x${metadata.height}` : 'N/A'}</p>
          <p>{metadata ? `Size: ${(metadata.size / (1024 ** 3)).toFixed(2)} GB` : 'N/A'}</p>
          <p>{video?.status ? `Status: ${video.status}` : 'N/A'}</p>
        </div>
      )}

      {/* Video Details and Exit Button in Swipe Mode */}
      {isSwipeMode && (
        <div className="overlay-description">
          <p className="video-name">{video.original_name}</p>
          {isEditingHashtags ? (
            <div className="edit-hashtags">
              <input type="text" value={hashtags} onChange={(e) => setHashtags(e.target.value)} onBlur={handleSaveHashtags} onKeyDown={handleKeyDown} onClick={(e) => e.stopPropagation()} />
            </div>
          ) : (
            <p className="video-hashtags" onClick={(e) => { e.stopPropagation(); setIsEditingHashtags(true); }} onDoubleClick={(e) => { e.stopPropagation(); setIsEditingHashtags(true); }}>{hashtags || 'Add hashtags'}</p>
          )}
        </div>
      )}

      {/* Video Details and Checkbox - Hidden in Swipe Mode */}
      {!isSwipeMode && (
        <>
          <div className="video-details">
            <div className="video-info">
              <p className="video-name">{video.original_name}</p>
              <p className="video-date">{metadata?.upload_date ? formatDate(metadata.upload_date) : 'N/A'}</p>
              <p className="video-duration">{metadata?.duration ? formatDuration(metadata.duration) : 'N/A'}</p>
              {isEditingHashtags ? (
                <div className="edit-hashtags">
                  <input type="text" value={hashtags} onChange={(e) => setHashtags(e.target.value)} onBlur={handleSaveHashtags} onKeyDown={handleKeyDown} onClick={(e) => e.stopPropagation()} />
                </div>
              ) : (
                <p className="video-hashtags" onClick={(e) => { e.stopPropagation(); setIsEditingHashtags(true); }} onDoubleClick={(e) => { e.stopPropagation(); setIsEditingHashtags(true); }}>{hashtags || 'Add hashtags'}</p>
              )}
            </div>
          </div>
          <input
            type="checkbox"
            className="select-checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            onClick={(e) => e.stopPropagation()}
          />
        </>
      )}

      {/* Error Flag, Progress Bar, etc. */}
      {processingError && <div className="error-flag">Processing Failed</div>}
      {progress !== null && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      {/* Upload Progress Bar */}
      {video.status === 'Uploading...' && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${video.progress}%` }}></div>
        </div>
      )}
    </li>
  );
}

export default VideoListItem;
